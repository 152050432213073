import React from 'react'
import './BodyContent.css'
import { BiTrendingUp } from "react-icons/bi"
import { Link, Outlet } from 'react-router-dom'


const BodyContent = () => {
  return (
    <>
    
    <Outlet/>
    </>
     
  )
}
export default BodyContent