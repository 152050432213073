import React from 'react'
import './LaunchpadProtocol.css'


const LaunchpadProtocol = () => {
  return (
    <div className='launchpad_section'>
      <div className='container'>
        <div className='launchpad_main'>
          <div className='launchpad_protocol_text'>
            
            <h1>MetaSale Admin Panel</h1>
           
          </div>
        </div>

     

      </div>
    </div>
  )
}

export default LaunchpadProtocol